import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "primary-button"
    }}>{`Primary button`}</h2>
    <ComponentCode name="Primary button" component="button" variation="button--primary" hasReactVersion="buttons--default" hasVueVersion="button--primary" hasAngularVersion="?path=/story/button--basic" codepen="xeZxLe" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "secondary-button"
    }}>{`Secondary button`}</h2>
    <ComponentCode name="Secondary button" component="button" variation="button--secondary" hasReactVersion="buttons--default" hasVueVersion="button--secondary" hasAngularVersion="?path=/story/button--basic&knob-Button kind=secondary&knob-Size of the buttons=normal" codepen="OGMJxW" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "tertiary-button"
    }}>{`Tertiary button`}</h2>
    <ComponentCode name="Tertiary button" component="button" variation="button--tertiary" hasReactVersion="buttons--default" hasVueVersion="button--tertiary" hasAngularVersion="?path=/story/button--basic&knob-Button kind=tertiary&knob-Size of the buttons=normal" codepen="VNewrL" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "ghost-button"
    }}>{`Ghost button`}</h2>
    <ComponentCode name="Ghost button" component="button" variation="button--ghost" hasReactVersion="buttons--default" hasVueVersion="button--ghost" hasAngularVersion="?path=/story/button--basic&knob-Button kind=ghost&knob-Size of the buttons=normal" codepen="MRKWZz" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "danger-button"
    }}>{`Danger button`}</h2>
    <ComponentCode name="Danger button" component="button" variation="button--danger" hasReactVersion="buttons--default" hasVueVersion="button--danger" hasAngularVersion="?path=/story/button--basic&knob-Button kind=danger&knob-Size of the buttons=normal" codepen="wZMvNP" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "small-primary-button"
    }}>{`Small primary button`}</h2>
    <ComponentCode name="Small primary button" component="button" variation="button--primary--small" hasReactVersion="buttons--default" hasVueVersion="button--primary&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=primary&knob-Size of the buttons=sm" codepen="oObNVb" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "small-secondary-button"
    }}>{`Small secondary button`}</h2>
    <ComponentCode name="Small secondary button" component="button" variation="button--secondary--small" hasReactVersion="buttons--default" hasVueVersion="button--secondary&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=secondary&knob-Size of the buttons=sm" codepen="ROrNbp" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "small-tertiary-button"
    }}>{`Small tertiary button`}</h2>
    <ComponentCode name="Small tertiary button" component="button" variation="button--tertiary--small" hasReactVersion="buttons--default" hasVueVersion="button--tertiary&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=tertiary&knob-Size of the buttons=sm" codepen="ZZQYGN" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "small-ghost-button"
    }}>{`Small ghost button`}</h2>
    <ComponentCode name="Small ghost button" component="button" variation="button--ghost--small" hasReactVersion="buttons--default" hasVueVersion="button--ghost&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=ghost&knob-Size of the buttons=sm" codepen="eoJmpw" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "small-danger-button"
    }}>{`Small danger button`}</h2>
    <ComponentCode name="Small danger button" component="button" variation="button--danger--small" hasReactVersion="buttons--default" hasVueVersion="button--danger&knob-small=true" hasAngularVersion="?path=/story/button--basic&knob-Button kind=danger&knob-Size of the buttons=sm" codepen="KYVwXY" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "button-set"
    }}>{`Button set`}</h2>
    <ComponentCode name="Button set" component="button" variation="button--set" hasReactVersion="buttons--sets-of-buttons" hasAngularVersion="?path=/story/button--basic" codepen="pBgvdX" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="button" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      